import { action } from 'satcheljs';

import { TActionCallback } from '@totopkg/shared-util-common';

import { NSConn } from '../lib/neffos';

export const reconnectNamespaceAction = action('reconnectNamespaceAction', (namespace: string, callback?: TActionCallback<NSConn>) => ({
  namespace,
  callback
}));
