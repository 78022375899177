import { orchestrator } from 'satcheljs';

import { pingAction } from '../actions/ping.action';
import { EDefaultNamespaceEvent } from '../constant/default-namespace-event.const';
import { DEFAULT_NAMESPACE } from '../constant/default-namespace.const';
import { namespaceConnectionSelector } from '../selectors/namespace-connection.selector';

orchestrator(pingAction, () => {
  const defaultNsConn = namespaceConnectionSelector(DEFAULT_NAMESPACE);
  defaultNsConn?.emit(EDefaultNamespaceEvent.PING, 'ping');
});
