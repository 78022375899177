import { mutatorAction } from 'satcheljs';

import { NSConn } from '../lib/neffos';
import { getSocketStore } from '../store/socket.store';

export const setNamespaceConnectionAction = mutatorAction(
  'setNamespaceConnectionAction',
  (namespace: string, connection: NSConn | undefined) => {
    if (!connection) {
      getSocketStore().namespace.delete(namespace);
    } else {
      getSocketStore().namespace.set(namespace, connection);
    }
  }
);
