import { orchestrator } from 'satcheljs';

import { connectNamespaceAction } from '../actions/connect-namespace.action';
import { namespaceConnectionStateSelector } from '../selectors/namespace-connection-state.selector';
import { getSocketStore } from '../store/socket.store';

orchestrator(connectNamespaceAction, async actionMessage => {
  const { namespace, callback } = actionMessage;

  try {
    if (!namespace) return;

    const _state = namespaceConnectionStateSelector(namespace);
    if (_state === 'CONNECTED' || _state === 'CONNECTING') return;

    /** random time for prevent connect multiple namespace at same time */
    await new Promise(resolve => {
      setTimeout(() => {
        resolve(undefined);
      }, Math.random() * 1000);
    });

    const _nsConn = await getSocketStore().dial?.connect(namespace);
    callback?.success?.(_nsConn);
  } catch (error) {
    console.error(`[SocketStore] connectNamespaceAction(${namespace}):`, error);
    callback?.error?.(error?.toString());
  } finally {
    callback?.finally?.();
  }
});
