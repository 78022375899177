import { ObservableMap } from 'mobx';
import { mutatorAction } from 'satcheljs';

import { Room } from '../lib/neffos';
import { getSocketStore } from '../store/socket.store';

export const setRoomAction = mutatorAction('setRoomAction', (namespace: string, roomId: string, room: Room | undefined) => {
  if (getSocketStore().rooms.has(namespace)) {
    !room ? getSocketStore().rooms.get(namespace)?.delete(roomId) : getSocketStore().rooms.get(namespace)?.set(roomId, room);
  } else if (room != null) {
    getSocketStore().rooms.set(namespace, new ObservableMap().set(roomId, room));
  }
});
