import { action } from 'satcheljs';

import { TActionCallback } from '@totopkg/shared-util-common';

export const disconnectNamespaceAction = action(
  'disconnectNamespaceAction',
  (namespace: string | undefined, callback?: TActionCallback) => {
    return { namespace, callback };
  }
);
