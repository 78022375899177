import { ObservableMap } from 'mobx';
import { mutatorAction } from 'satcheljs';

import { ISOString } from '@totopkg/shared-util-date-time';

import { getSocketStore } from '../store/socket.store';
import { TSocketEventId, TSocketNamespaceId } from '../store/socket.type';

export const setNamespaceEventLastEmitTimeAction = mutatorAction(
  'setNamespaceEventLastEmitTimeAction',
  (namespace: TSocketNamespaceId, eventId: TSocketEventId, time: ISOString) => {
    let _namespaceEventLastEmitTimeMap = getSocketStore().namespaceEventLastEmitTime.get(namespace);

    if (_namespaceEventLastEmitTimeMap == null) {
      _namespaceEventLastEmitTimeMap = new ObservableMap();
      getSocketStore().namespaceEventLastEmitTime.set(namespace, _namespaceEventLastEmitTimeMap);
    }

    _namespaceEventLastEmitTimeMap.set(eventId, time);
  }
);
