import { mutatorAction } from 'satcheljs';

import { getSocketStore } from '../store/socket.store';
import { TSocketConnectionConfig } from '../store/socket.type';

export const setSocketConnectionConfigAction = mutatorAction(
  'setSocketConnectionConfigAction',
  (config: TSocketConnectionConfig | undefined) => {
    getSocketStore().socketConnectionConfig = config;
  }
);
