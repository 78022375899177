import { Options } from 'currency.js';
import { ObservableMap } from 'mobx';

import { ISOString } from '@totopkg/shared-util-date-time';

import { Conn, NSConn, Room } from '../lib/neffos';
import { ISocketEvent } from '../types/event.type';
import { IActiveSocketNamespace } from '../types/socket-config.type';

export type TSocketNamespaceId = string;

export type TSocketEventId = string;

export type TSocketConnectionState = 'CONNECTING' | 'CONNECTED' | 'DISCONNECTED';

export type TSocketConnectionConfig = {
  url: string;
  events?: TSocketNamespaceEvents;
  options?: Options;
};

export type TSocketNamespaceEvents = Record<TSocketNamespaceId, ISocketEvent>;

export type TSocketRoomId = string;
export type TNullableSocketRoomId = TSocketRoomId | null | undefined;

export interface ISocketStore {
  dial: Conn | undefined;
  /** store connection config use to dial and redial */
  socketConnectionConfig: TSocketConnectionConfig | undefined;
  /** store namespace ui config */
  namespaceConfig: ObservableMap<TSocketNamespaceId, IActiveSocketNamespace>;

  namespace: ObservableMap<TSocketNamespaceId, NSConn>;
  namespaceState: ObservableMap<TSocketNamespaceId, TSocketConnectionState>;
  namespaceEventLastEmitTime: ObservableMap<TSocketNamespaceId, ObservableMap<TSocketEventId, ISOString>>;

  rooms: ObservableMap<TSocketNamespaceId, ObservableMap<TSocketRoomId, Room>>;
  roomsState: ObservableMap<TSocketRoomId, TSocketConnectionState>;
}
