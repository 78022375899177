import { action } from 'satcheljs';

import { TActionCallback } from '@totopkg/shared-util-common';

import { NSConn } from '../lib/neffos';

export const connectNamespaceAction = action(
  'connectNamespaceAction',
  (namespace: string | undefined, callback?: TActionCallback<NSConn>) => {
    return { namespace, callback };
  }
);
