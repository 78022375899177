import { orchestrator } from 'satcheljs';

import { disconnectNamespaceAction } from '../actions/disconnect-namespace.action';
import { setNamespaceConnectionAction } from '../mutator-action/set-namespace-connection.action';
import { namespaceConnectionSelector } from '../selectors/namespace-connection.selector';

orchestrator(disconnectNamespaceAction, async actionMessage => {
  const { namespace, callback } = actionMessage;

  try {
    if (!namespace) return;

    const nsConn = namespaceConnectionSelector(namespace);
    await nsConn?.disconnect();
    setNamespaceConnectionAction(namespace, undefined);
    callback?.success?.();
  } catch (error) {
    console.error(`[SocketStore] disconnectNamespaceAction(${namespace}):`, error);
    callback?.error?.(error?.toString());
  } finally {
    callback?.finally?.();
  }
});
