import { orchestrator } from 'satcheljs';

import { joinRoomAction } from '../actions/join-room.action';
import { namespaceConnectionSelector } from '../selectors/namespace-connection.selector';
import { roomStateSelector } from '../selectors/room-state.selector';

orchestrator(joinRoomAction, async actionMessage => {
  const { namespace, roomId, callback } = actionMessage;

  try {
    if (!namespace || !roomId) return;

    const _roomState = roomStateSelector(namespace, roomId);
    if (_roomState === 'CONNECTING' || _roomState === 'CONNECTED') return;

    const _nsConn = namespaceConnectionSelector(namespace);
    if (!_nsConn) return;

    /** random delay prevent conflict multi connection on same time */
    await new Promise(resolve => {
      setTimeout(() => {
        resolve(undefined);
      }, Math.random() * 1000);
    });

    const _room = await _nsConn.joinRoom(roomId);
    callback?.success?.(_room);
  } catch (error) {
    console.error(`[SocketStore] joinRoomAction(${namespace}, ${roomId}):`, error);
    callback?.error?.(error?.toString());
  } finally {
    callback?.finally?.();
  }
});
