const SOCKET_URL = '{{protocol}}://{{domain}}/ws/socket';

export function generateSocketUrl(token: string | undefined) {
  if (!token) return undefined;

  if (!token) {
    return null;
  }

  const socketProtocol = window.location.protocol.includes('https') ? 'wss' : 'ws';
  const domain = window.location.host;

  return `${SOCKET_URL.replace('{{protocol}}', socketProtocol).replace('{{domain}}', domain)}?token=${token}`;
}
