import { action } from 'satcheljs';

import { TActionCallback } from '@totopkg/shared-util-common';

import { Room } from '../lib/neffos';
import { TNullableSocketRoomId } from '../store/socket.type';

export const joinRoomAction = action(
  'joinRoomAction',
  (namespace: string | undefined, roomId: TNullableSocketRoomId, callback?: TActionCallback<Room>) => {
    return {
      namespace,
      roomId,
      callback
    };
  }
);
