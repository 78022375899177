import { action } from 'satcheljs';

import { TActionCallback } from '@totopkg/shared-util-common';

import { Conn } from '../lib/neffos';
import { TSocketConnectionConfig } from '../store/socket.type';

export const redialAction = action('redialAction', (message: { config?: TSocketConnectionConfig; callback?: TActionCallback<Conn> }) => ({
  ...message
}));
