import { mutatorAction } from 'satcheljs';

import { getSocketStore } from '../store/socket.store';
import { TSocketNamespaceId } from '../store/socket.type';

export const clearNamespaceEventLastEmitTimeAction = mutatorAction(
  'clearNamespaceEventLastEmitTimeAction',
  (namespace: TSocketNamespaceId) => {
    getSocketStore().namespaceEventLastEmitTime.delete(namespace);
  }
);
