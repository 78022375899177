import { TSocketEventId, TSocketNamespaceId } from '../store/socket.type';

export interface IActiveSocketNamespace {
  namespace?: TSocketNamespaceId;
  globalRoomId?: string;

  /** time delay between emit one action of namespace */
  eventThreshold?: Record<TSocketEventId | 'default', number>;
}

export interface ISocketConfig {
  activeSockets?: IActiveSocketNamespace[];
}
