import { action } from 'satcheljs';

import { TActionCallback } from '@totopkg/shared-util-common';

import { TNullableSocketRoomId } from '../store/socket.type';

export const leaveRoomAction = action(
  'leaveRoomAction',
  (namespace: string | undefined, roomId: TNullableSocketRoomId, callback?: TActionCallback<string>) => {
    return { namespace, roomId, callback };
  }
);
