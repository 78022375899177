import { orchestrator } from 'satcheljs';

import { connectNamespaceAction } from '../actions/connect-namespace.action';
import { joinRoomAction } from '../actions/join-room.action';
import { reconnectNamespaceAction } from '../actions/re-connect-namespace.action';
import { allRoomsSelector } from '../selectors/all-rooms.selector';

orchestrator(reconnectNamespaceAction, actionMessage => {
  const { namespace, callback } = actionMessage;

  connectNamespaceAction(namespace, {
    ...callback,
    success: nsConn => {
      /** re-join all prev joined room of namespace */
      allRoomsSelector(namespace)?.forEach((room: any) => {
        joinRoomAction(namespace, room.name);
      });
      callback?.success?.(nsConn);
    }
  });
});
