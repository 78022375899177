import { TEventHandler } from './event-handler.type';

export interface ISocketEvent {
  /* The OnNamespaceConnect is the event name that it's fired on before namespace connect. */
  _OnNamespaceConnect?: TEventHandler;

  /* The OnNamespaceConnected is the event name that it's fired on after namespace connect. */
  _OnNamespaceConnected?: TEventHandler;

  /* The OnNamespaceDisconnect is the event name that it's fired on namespace disconnected. */
  _OnNamespaceDisconnect?: TEventHandler;

  /* The OnRoomJoin is the event name that it's fired on before room join. */
  _OnRoomJoin?: TEventHandler;

  /* The OnRoomJoined is the event name that it's fired on after room join. */
  _OnRoomJoined?: TEventHandler;

  /* Error on room join failed */
  onRoomJoinError?: TEventHandler;

  /* The OnRoomLeave is the event name that it's fired on before room leave. */
  _OnRoomLeave?: TEventHandler;

  /* The OnRoomLeft is the event name that it's fired on after room leave. */
  _OnRoomLeft?: TEventHandler;

  /* The OnAnyEvent is the event name that it's fired, if no incoming event was registered, it's a "wildcard". */
  _OnAnyEvent?: TEventHandler;

  /* The OnNativeMessage is the event name, which if registered on empty "" namespace it accepts native messages(Message.Body and Message.IsNative is filled only). */
  _OnNativeMessage?: TEventHandler;

  /* handle any error */
  onError?: TEventHandler;

  /* other event */
  [eventName: string]: TEventHandler | undefined;
}
