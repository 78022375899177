import { orchestrator } from 'satcheljs';

import { closeDialAction } from '../actions/close-dial.action';
import { setDialAction } from '../mutator-action/set-dial-conn.action';
import { dialSelector } from '../selectors/dial.selector';

orchestrator(closeDialAction, actionMessage => {
  const { callback } = actionMessage;

  const _openingDial = dialSelector();
  if (!_openingDial) return;

  try {
    _openingDial.close();
    setDialAction(undefined);
  } catch (error) {
    console.error('[SocketStore] closeDialAction:', error);
    callback?.error?.(error?.toString());
  } finally {
    callback?.finally?.();
  }
});
