import { Tail } from '@totopkg/shared-util-common';

import { closeDialAction } from '../actions/close-dial.action';
import { connectNamespaceAction } from '../actions/connect-namespace.action';
import { disconnectNamespaceAction } from '../actions/disconnect-namespace.action';
import { emitAction } from '../actions/emit.action';
import { joinRoomAction } from '../actions/join-room.action';
import { leaveRoomAction } from '../actions/leave-room.action';
import { openDialAction } from '../actions/open-dial.action';
import { reconnectNamespaceAction } from '../actions/re-connect-namespace.action';
import { redialAction } from '../actions/re-dial.action';
import { lastEventEmitTimeSelector } from '../selectors';
import { dialSelector } from '../selectors/dial.selector';
import { globalRoomIdSelector } from '../selectors/global-room-id.selector';
import { globalRoomStateSelector } from '../selectors/global-room-state.selector';
import { globalRoomSelector } from '../selectors/global-room.selector';
import { namespaceConfigSelector } from '../selectors/namespace-config.selector';
import { namespaceConnectionStateSelector } from '../selectors/namespace-connection-state.selector';
import { namespaceConnectionSelector } from '../selectors/namespace-connection.selector';
import { roomStateSelector } from '../selectors/room-state.selector';
import { roomSelector } from '../selectors/room.selector';
import { socketConnectionConfigSelector } from '../selectors/socket-connection-config.selector';
import { TNullableSocketRoomId, TSocketNamespaceEvents } from '../store/socket.type';

export abstract class BaseNamespaceSocket {
  public readonly namespace: string;

  protected constructor(namespace: string) {
    this.namespace = namespace;

    /** bind this context */
    this.openDial = this.openDial.bind(this);
    this.closeDial = this.closeDial.bind(this);
    this.redial = this.redial.bind(this);

    this.connectNamespace = this.connectNamespace.bind(this);
    this.disconnectNamespace = this.disconnectNamespace.bind(this);
    this.reconnectNamespace = this.reconnectNamespace.bind(this);
    this.getNamespaceConfig = this.getNamespaceConfig.bind(this);

    this.getRoom = this.getRoom.bind(this);
    this.getRoomState = this.getRoomState.bind(this);
    this.joinRoom = this.joinRoom.bind(this);
    this.leaveRoom = this.leaveRoom.bind(this);

    this.joinGlobalRoom = this.joinGlobalRoom.bind(this);
    this.leaveGlobalRoom = this.leaveGlobalRoom.bind(this);

    this.emit = this.emit.bind(this);
  }

  public get dial() {
    return dialSelector();
  }

  public get isReadyToDial() {
    return socketConnectionConfigSelector() != null;
  }

  public get socketConnectionConfig() {
    return socketConnectionConfigSelector();
  }

  public get namespaceConnection() {
    return namespaceConnectionSelector(this.namespace);
  }

  public get namespaceConnectionState() {
    return namespaceConnectionStateSelector(this.namespace);
  }

  public get globalRoomId() {
    return globalRoomIdSelector(this.namespace);
  }

  public get globalRoom() {
    return globalRoomSelector(this.namespace);
  }

  public get globalRoomState() {
    return globalRoomStateSelector(this.namespace);
  }

  public abstract getNamespaceEvents(): TSocketNamespaceEvents;

  public openDial(...args: Parameters<typeof openDialAction>) {
    openDialAction(...args);
  }

  public closeDial(...args: Parameters<typeof closeDialAction>) {
    closeDialAction(...args);
  }

  public redial(...args: Parameters<typeof redialAction>) {
    redialAction(...args);
  }

  public connectNamespace(...args: Tail<Parameters<typeof connectNamespaceAction>>) {
    connectNamespaceAction(this.namespace, ...args);
  }

  public disconnectNamespace(...args: Tail<Parameters<typeof disconnectNamespaceAction>>) {
    disconnectNamespaceAction(this.namespace, ...args);
  }

  public reconnectNamespace(...args: Tail<Parameters<typeof reconnectNamespaceAction>>) {
    reconnectNamespaceAction(this.namespace, ...args);
  }

  public getNamespaceConfig<T = unknown>() {
    return namespaceConfigSelector<T>(this.namespace);
  }

  public getRoom(roomId: TNullableSocketRoomId) {
    return roomSelector(this.namespace, roomId);
  }

  public getRoomState(roomId: TNullableSocketRoomId) {
    return roomStateSelector(this.namespace, roomId);
  }

  public getLastEventEmitTime(...args: Tail<Parameters<typeof lastEventEmitTimeSelector>>) {
    return lastEventEmitTimeSelector(this.namespace, ...args);
  }

  public joinRoom(...args: Tail<Parameters<typeof joinRoomAction>>) {
    joinRoomAction(this.namespace, ...args);
  }

  public leaveRoom(...args: Tail<Parameters<typeof leaveRoomAction>>) {
    leaveRoomAction(this.namespace, ...args);
  }

  public joinGlobalRoom(...args: Tail<Parameters<typeof this.joinRoom>>) {
    const _roomId = this.globalRoomId;
    if (!_roomId) return;

    this.joinRoom(_roomId, ...args);
  }

  public leaveGlobalRoom(...args: Tail<Parameters<typeof this.leaveRoom>>) {
    this.leaveRoom(this.globalRoomId, ...args);
  }

  public emit(...args: Tail<Parameters<typeof emitAction>>) {
    emitAction(this.namespace, ...args);
  }
}
