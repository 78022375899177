import { mutatorAction } from 'satcheljs';

import { getSocketStore } from '../store/socket.store';
import { TNullableSocketRoomId, TSocketConnectionState } from '../store/socket.type';

export const setRoomStateAction = mutatorAction(
  'setRoomStateAction',
  (namespace: string | undefined, roomId: TNullableSocketRoomId, state: TSocketConnectionState) => {
    if (!namespace || !roomId) return;
    getSocketStore().roomsState.set(`${namespace}-${roomId}`, state);
  }
);
