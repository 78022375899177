import { orchestrator } from 'satcheljs';

import { dayjs } from '@totopkg/shared-util-date-time';

import { emitAction } from '../actions/emit.action';
import { setNamespaceEventLastEmitTimeAction } from '../mutator-action/set-namespace-event-last-emit-time.action';
import { namespaceConfigSelector } from '../selectors/namespace-config.selector';
import { namespaceEventLastEmitTimeSelector } from '../selectors/namespace-event-last-emit-time.selector';
import { roomSelector } from '../selectors/room.selector';

orchestrator(emitAction, actionMessage => {
  const { namespace, roomId, eventId, text, callback } = actionMessage;

  const roomSocket = roomSelector(namespace, roomId);

  try {
    if (roomSocket == null) {
      throw new Error(`[SocketStore] emitAction: can't find room ${roomId} in namespace ${namespace}`);
    }

    /** check event emit threshold */
    const _namespaceConfig = namespaceConfigSelector(namespace);
    const _thresholdTime = _namespaceConfig?.eventThreshold?.[eventId] ?? _namespaceConfig?.eventThreshold?.['default'];

    if (_thresholdTime != null) {
      const _lastEmitTime = namespaceEventLastEmitTimeSelector(namespace, eventId);

      const timezoneDayjs: any = dayjs();
      if (timezoneDayjs.tz().diff(_lastEmitTime) < _thresholdTime) {
        throw new Error('error.spamEvent');
      }

      setNamespaceEventLastEmitTimeAction(namespace, eventId, timezoneDayjs.tz().toISOString());
    }

    const _emitSuccess = roomSocket.emit(eventId, text);
    if (!_emitSuccess) {
      throw new Error(`[SocketStore] emitAction: ${eventId} failed`);
    }

    callback?.success?.();
  } catch (error) {
    console.error(`[SocketStore] emitAction(${namespace}, ${roomId}, ${eventId}, ${text}):`, error);
    callback?.error?.(error?.toString());
  } finally {
    callback?.finally?.();
  }
});
