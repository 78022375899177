import { mutatorAction } from 'satcheljs';

import { getSocketStore } from '../store/socket.store';
import { TSocketConnectionState } from '../store/socket.type';

export const setNamespaceConnectionStateAction = mutatorAction(
  'setNamespaceConnectionStateAction',
  (namespace: string, state: TSocketConnectionState) => {
    getSocketStore().namespaceState.set(namespace, state);
  }
);
