import { orchestrator } from 'satcheljs';

import { openDialAction } from '../actions/open-dial.action';
import { reconnectNamespaceAction } from '../actions/re-connect-namespace.action';
import { redialAction } from '../actions/re-dial.action';
import { NSConn } from '../lib/neffos';
import { allNamespacesSelector } from '../selectors/all-namespaces.selector';
import { socketConnectionConfigSelector } from '../selectors/socket-connection-config.selector';

orchestrator(redialAction, actionMessage => {
  const { config, callback } = actionMessage;

  const _dialConfig = config ?? socketConnectionConfigSelector();

  if (!_dialConfig) return callback?.error?.('[SocketStore] redialAction: config was undefined!');

  openDialAction(_dialConfig, {
    callback: {
      ...callback,
      success: conn => {
        /** re-connect all prev connected namespace */
        allNamespacesSelector().forEach((namespaceConnection: NSConn) => {
          reconnectNamespaceAction(namespaceConnection.namespace);
        });
        callback?.success?.(conn);
      }
    },
    force: true
  });
});
