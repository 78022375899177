import { action } from 'satcheljs';

import { TActionCallback } from '@totopkg/shared-util-common';

import { Conn } from '../lib/neffos';
import { TSocketConnectionConfig } from '../store/socket.type';

export const openDialAction = action(
  'openDialAction',
  (
    config?: TSocketConnectionConfig,
    options?: {
      callback?: TActionCallback<Conn>;
      force?: boolean;
    }
  ) => {
    return {
      config,
      ...options
    };
  }
);
