import { action } from 'satcheljs';

import { TActionCallback } from '@totopkg/shared-util-common';

import { TNullableSocketRoomId, TSocketEventId, TSocketNamespaceId } from '../store/socket.type';

export const emitAction = action(
  'emitAction',
  (
    namespace: TSocketNamespaceId,
    roomId: TNullableSocketRoomId,
    options: {
      eventId: TSocketEventId;
      text: string;
      callback?: TActionCallback;
    }
  ) => {
    return {
      namespace,
      roomId,
      ...options
    };
  }
);
