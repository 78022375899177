import { orchestrator } from 'satcheljs';

import { LoadingStore } from '@totopkg/shared-util-common';

import { openDialAction } from '../actions/open-dial.action';
import { pingAction } from '../actions/ping.action';
import { DEFAULT_NAMESPACE } from '../constant/default-namespace.const';
import { LOADING_DIAL_OPEN } from '../constant/loading-key.const';
import * as neffos from '../lib/neffos';
import { NSConn } from '../lib/neffos';
import { setDialAction } from '../mutator-action/set-dial-conn.action';
import { setNamespaceConnectionStateAction } from '../mutator-action/set-namespace-connection-state.action';
import { setNamespaceConnectionAction } from '../mutator-action/set-namespace-connection.action';
import { setRoomStateAction } from '../mutator-action/set-room-state.action';
import { setRoomAction } from '../mutator-action/set-room.action';
import { setSocketConnectionConfigAction } from '../mutator-action/set-socket-connection-config.action';
import { dialSelector } from '../selectors/dial.selector';
import { socketConnectionConfigSelector } from '../selectors/socket-connection-config.selector';
import { TSocketNamespaceEvents } from '../store/socket.type';

orchestrator(openDialAction, async actionMessage => {
  const { config, callback, force } = actionMessage;

  try {
    if (LoadingStore.localLoadingSelector(LOADING_DIAL_OPEN) || (!force && dialSelector() != null)) return;

    const _socketConfig = config ?? socketConnectionConfigSelector();

    if (!_socketConfig) throw new Error('[SocketStore] openDialAction: socket config was undefined');

    const {
      url,
      events = {},
      options = {
        reconnect: 5000
      }
    } = _socketConfig;

    LoadingStore.updateLocalLoadingAction(LOADING_DIAL_OPEN, true);

    /** save dial config for reconnect */
    setSocketConnectionConfigAction(_socketConfig);

    /** default namespace ping, pong events */
    const _defaultNamespaceEvents: TSocketNamespaceEvents = {
      [DEFAULT_NAMESPACE]: {
        _OnNamespaceConnected: () => {
          setTimeout(pingAction, 30000);
        },
        pong: () => {
          setTimeout(pingAction, 30000);
        }
      }
    };

    /** generating handler for namespace default events */
    let _processedNamespaceEvents: TSocketNamespaceEvents = {};

    const _combineNamespaceEvents = {
      ..._defaultNamespaceEvents,
      ...events
    };

    Object.keys(_combineNamespaceEvents).forEach(async namespace => {
      _processedNamespaceEvents = {
        ..._processedNamespaceEvents,
        [namespace]: {
          ..._combineNamespaceEvents[namespace],
          /** Namespace event and state */
          _OnNamespaceConnect: (nsConn: NSConn, msg: any) => {
            setNamespaceConnectionStateAction(namespace, 'CONNECTING');
            _combineNamespaceEvents[namespace]?._OnNamespaceConnect?.(nsConn, msg);
          },
          _OnNamespaceConnected: (nsConn: NSConn, msg: any) => {
            setNamespaceConnectionStateAction(namespace, 'CONNECTED');
            setNamespaceConnectionAction(namespace, nsConn);
            _combineNamespaceEvents[namespace]?._OnNamespaceConnected?.(nsConn, msg);
          },
          _OnNamespaceDisconnect: (nsConn: NSConn, msg: any) => {
            setNamespaceConnectionStateAction(namespace, 'DISCONNECTED');
            _combineNamespaceEvents[namespace]?._OnNamespaceDisconnect?.(nsConn, msg);
          },
          onError: (nsConn: NSConn, msg: any) => {
            console.error('[SocketStore] onError:', msg);
            _combineNamespaceEvents[namespace]?.onError?.(nsConn, msg);
          },
          /** Room event and state */
          _OnRoomJoin: (nsConn: NSConn, msg: any) => {
            setRoomStateAction(namespace, msg.Room, 'CONNECTING');
            _combineNamespaceEvents[namespace]?._OnRoomJoin?.(nsConn, msg);
          },
          _OnRoomJoined: (nsConn: NSConn, msg: any) => {
            setRoomStateAction(namespace, msg.Room, 'CONNECTED');
            setRoomAction(msg.Namespace, msg.Room, nsConn.room(msg.Room));
            _combineNamespaceEvents[namespace]?._OnRoomJoined?.(nsConn, msg);
          },
          _OnRoomLeft: (nsConn: NSConn, msg: any) => {
            setRoomStateAction(namespace, msg.Room, 'DISCONNECTED');
            _combineNamespaceEvents[namespace]?._OnRoomLeft?.(nsConn, msg);
          },
          onRoomJoinError: (nsConn: NSConn, msg: any) => {
            try {
              const errorBody: {
                roomId?: string;
                code?: string;
              } = JSON.parse(msg.Body);
              setRoomStateAction(namespace, errorBody.roomId, 'DISCONNECTED');
              setRoomAction(msg.Namespace, msg.Room, undefined);
            } catch (error) {
              console.error('[SocketStore] onRoomJoinError:', error);
            }
            _combineNamespaceEvents[namespace]?.onRoomJoinError?.(nsConn, msg);
          }
        }
      };
    });

    if (Object.keys(_processedNamespaceEvents).length > 0) {
      /** close prev dial */
      const _prevDial = dialSelector();
      _prevDial?.close();

      /** open new dial */
      const _newDial = await neffos.dial(url, _processedNamespaceEvents, options);

      /** connect default namespace */
      await _newDial.connect(DEFAULT_NAMESPACE);

      setDialAction(_newDial);
      callback?.success?.(_newDial);
    }
  } catch (error) {
    console.error('[SocketStore] openDialAction:', error);
    callback?.error?.(error?.toString());
  } finally {
    LoadingStore.updateLocalLoadingAction(LOADING_DIAL_OPEN, false);
    callback?.finally?.();
  }
});
