import { ObservableMap } from 'mobx';
import { createStore } from 'satcheljs';

import { ISocketStore } from './socket.type';

const initStore: ISocketStore = {
  namespace: new ObservableMap(),
  namespaceState: new ObservableMap(),
  rooms: new ObservableMap(),
  dial: undefined,
  roomsState: new ObservableMap(),
  namespaceConfig: new ObservableMap(),
  socketConnectionConfig: undefined,
  namespaceEventLastEmitTime: new ObservableMap()
};

export const getSocketStore = createStore('socket-store', initStore);
