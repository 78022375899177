import { mutatorAction } from 'satcheljs';

import { getSocketStore } from '../store/socket.store';
import { TSocketNamespaceId } from '../store/socket.type';
import { IActiveSocketNamespace } from '../types/socket-config.type';

export const setNamespaceConfigAction = mutatorAction(
  'setNamespaceConfigAction',
  (namespace: TSocketNamespaceId, config: IActiveSocketNamespace | undefined) => {
    if (config == null) {
      getSocketStore().namespaceConfig.delete(namespace);
      return;
    }

    getSocketStore().namespaceConfig.set(namespace, config);
  }
);
