import { orchestrator } from 'satcheljs';

import { leaveRoomAction } from '../actions/leave-room.action';
import { setRoomAction } from '../mutator-action/set-room.action';
import { roomSelector } from '../selectors/room.selector';

orchestrator(leaveRoomAction, actionMessage => {
  const { namespace, roomId, callback } = actionMessage;

  if (!namespace || !roomId) return;

  const nsRoom = roomSelector(namespace, roomId);

  if (nsRoom) {
    nsRoom
      .leave()
      .then(() => {
        setRoomAction(namespace, roomId, undefined);
        callback?.success?.(roomId);
      })
      .catch((error: any) => {
        console.error(`[SocketStore] leaveRoomAction(${namespace}, ${roomId}):`, error);
        callback?.error?.(error?.toString());
      })
      .finally(() => callback?.finally?.());
  }
});
